import { Controller } from '@hotwired/stimulus';

function waitForElement(querySelector, timeout){
    return new Promise((resolve, reject)=>{
        var timer = false;
        if(document.querySelectorAll(querySelector).length) return resolve();
        const observer = new MutationObserver(() => {
            if(document.querySelectorAll(querySelector).length){
            observer.disconnect();
            if(timer !== false) clearTimeout(timer);
            return resolve();
            }
        });
        observer.observe(document.body, {
            childList: true, 
            subtree: true
        });
        if(timeout) timer = setTimeout(()=>{
            observer.disconnect();
            reject();
        }, timeout);
    });
}

const createMapSymbolDefinition = (mapElement) => `
<div class="pole map-element" data-label-position="${mapElement.labelPosition}" data-id="${mapElement.id}"
    style="scale: ${mapElement.scale}; translate: ${mapElement.x}px ${mapElement.y}px;">
    <div class="map-element-imgs" style="rotate: ${mapElement.angle}deg">
        <div class="rotater">
            <i class="far fa-dot-circle"></i>
        </div>
        <img class="symbol-img" id="${mapElement.id}" src="${mapElement.symbol.icon}" height="40"/>
    </div>
    <p style="background-color: ${mapElement.labelColor}">${mapElement.number}</p>
    <img class="resize-img" src="/build/images/resize_triangle.svg"/>
    <img class="move-img" src="/build/images/move_multi-arrow.svg"/>
</div>
`;

export default class extends Controller {
    mapView;
    tabView;
    mapContent;
    windowZoomLevel;
    mapOffset = { x: 0, y: 0 };
    initialized = false;

    static values = {
        elements: Array,
        elementTypes: Array,
        scale: Number,
        offsetX: Number,
        offsetY: Number,
    }
    
    connect() {
        this.mapView = $(this.element).find('#mapDisplay');
        this.mapContent = $(this.element).find('#mapContent');

        waitForElement('#mapContent > object', 10000).then(() => {
            this.initMap();
        });
    }

    initMap() {
        this.mapContent.css('scale', this.scaleValue);
        this.mapContent.css('translate', this.offsetXValue + 'px ' + this.offsetYValue + 'px');

        this.elementsValue.forEach((elem) => this.addElementPointToMap({
            id: elem.id,
            type: elem.type,
            symbol: this.elementTypesValue.find((elemType) => elemType.value == elem.type),
            trackNumber: elem.trackNumber,
            number: elem.number,
            angle: elem.angle,
            scale: elem.zoom,
            x: elem.coordinateX,
            y: elem.coordinateY,
            installationType: elem.installationType,
            labelColor: elem.labelColor,
            labelPosition: elem.labelPosition,
        }));
    }

    addElementPointToMap(mapElement) {
        let newMapSymbol = $(createMapSymbolDefinition(mapElement));

        this.mapContent.append(newMapSymbol);

        return newMapSymbol;
    }
}