import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

    connect() {
        $('.toggle-show-password').on('click', this.toggleShowPassword);
        $('form').on('submit', () => $('toggle-show-password').off('click'));
    }
    
    toggleShowPassword() {
        var input = document.getElementById("password");
        input.type = input.type === "password" ? 'text' : 'password';
    }
}