import { Controller } from '@hotwired/stimulus';
import Tags from "bootstrap5-tags";
import 'jquery-validation';
import 'jquery-validation/dist/localization/messages_fr';

const dateRangePickerLocale = {
    applyLabel: "Définir",
    cancelLabel: "Annuler",
    format: "DD/MM/YYYY","weekLabel": "W",
    daysOfWeek: [
        "Dim",
        "Lun",
        "Mar",
        "Mer",
        "Jeu",
        "Ven",
        "Sam"
    ],
    monthNames: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre"
    ],
};

export default class extends Controller {
    prototype;
    selectedSubSiteId;
    nextIndex;

    static values = {
        restraintRowsModification: Boolean,
        managerId: String
    }

    connect() {
        // Take the first line as a prototype for the new lines
        this.prototype = $('#subSites tbody tr').first().html()
            .replace(/form_subSites_[0-9]*_/g, 'form_subSites__index__')
            .replace(/form\[subSites\]\[[0-9]*\]/g, 'form[subSites][_index_]');
        // Retrieve the current index for incrementing when inserting new lines
        this.nextIndex = $('#subSites').data('index');
        $('form').attr('id', 'worksiteSubsitesForm');
        $('#addSubSite').on('click', () => this.addNewRow());
        $('#addUser').on('click', () => $('#addUserModal').modal('show'));

        $('.technicians-dropdown select').each((i, elem) => {
            if(this.restraintRowsModificationValue && $(elem).parentsUntil('tbody').last().find('.site-manager-dropdown select').val() != this.managerIdValue) {
                $(elem).attr('readonly', 'readonly');
                $(elem).parentsUntil('tbody').last().hide();
            }
            Tags.init('#' + elem.id, { 'allowClear': true, 'clearEnd': true, 'badgeStyle': 'yellow', selected: $(elem).val() })
        });

        $('.btn-daterange').each((i, elem) => this.attachDateRangePicker(elem));
        $('.field-collection-delete-button').on('click', (e) => this.onDeleteRow(e));
        $('#deleteModal .btn-confirm').on('click', () => this.deleteNewRow());

        $('form').on('submit', () => {
            $('.actions, #addSubSite').hide();
        })

        $('#addUserModal #user_form_save').on('click', (e) => this.onSubmitUserCreation(e));
        $('#confirmCreateModal .btn-confirm').on('click', () => this.submitUserCreation());
    }

    attachDateRangePicker(elem) {
        $(elem).daterangepicker({
            startDate: $(elem).data('start'),
            endDate: $(elem).data('end'),
            opens: 'right',
            locale: dateRangePickerLocale
        }, (startDate, endDate) => {
            $(elem).parent().find('input').val(startDate.format('DD/MM/YY') + ' - ' + endDate.format('DD/MM/YY'));
        });        
    }

    addNewRow() {
        var maxEndDateTimestamp = Math.max(
            ...$('.date-range-input input').map((i, elem) => {
                var dateEndParts = $(elem).val().split(' - ')[1].split('/');
                return new Date('20' + parseInt(dateEndParts[2]), parseInt(dateEndParts[1]) - 1, parseInt(dateEndParts[0])).getTime();
            }).toArray()
        );

        var newRow = this.prototype.replaceAll('_index_', this.nextIndex);
        $('#subSites tbody').append(`<tr class="pole-line table-light" scope="row" data-id="${-this.nextIndex}">${newRow}</tr>`);
        var newRowElem = $(`#subSites tr[data-id="${-this.nextIndex}"]`)
        // Add delete button in case the prototype line doesn't have it
        newRowElem.find(`td`).last().html(`
            <button type="button" class="btn btn-link btn-link-danger field-collection-delete-button">
                <i class="far fa-trash-alt"></i>
            </button>
        `);
        newRowElem.find('.btn-daterange').each((i, elem) => {
            $(elem).data('start', new Date(maxEndDateTimestamp));
            $(elem).data('end', new Date(maxEndDateTimestamp + 24 * 3600 * 1000));
            this.attachDateRangePicker(elem);
        });
        newRowElem.find('.field-collection-delete-button').on('click', (e) => this.onDeleteRow(e));
        newRowElem.find('input, select').val(null);
        newRowElem.find('.date-range-input input').val(
            new Date(maxEndDateTimestamp).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: '2-digit' })
            + ' - '
            + new Date(maxEndDateTimestamp + 24 * 3600 * 1000).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: '2-digit' })
        );
        newRowElem.find('.status-label').text('Création en cours');

        newRowElem.find('.site-manager-dropdown select').val(this.managerIdValue);
        Tags.init(`#subSites [data-id="${-this.nextIndex}"] .technicians-dropdown select`, { 'allowClear': true, 'clearEnd': true, 'badgeStyle': 'yellow' });

        this.nextIndex += 1;
    }

    onDeleteRow(event) {
        this.selectedSubSiteId = $(event.target).parentsUntil('tbody').last().data('id');
        if(this.selectedSubSiteId < 0) this.deleteNewRow();
        else this.deleteExistingRow();
    }

    deleteNewRow() {
        $('.pole-line[data-id="' + this.selectedSubSiteId + '"]').remove();
        $('#deleteModal').modal('hide');
    }

    deleteExistingRow() {
        $('.pole-line[data-id="' + this.selectedSubSiteId + '"]').addClass('disabled');
        $('.pole-line[data-id="' + this.selectedSubSiteId + '"]').find('input, select, button').attr('disabled', 'disabled');
        $('#deleteModal').modal('hide');
    }

    onSubmitUserCreation(e) {
        e.preventDefault();
        $('#addUserModal #worksiteSubsitesForm .alert-danger').hide();

        var form = $('#addUserModal #worksiteSubsitesForm');
        form.validate();

        if(form.valid()) $('#confirmCreateModal').modal('show');

        return false;
    }

    submitUserCreation() {
        var form = $('#addUserModal #worksiteSubsitesForm');
        var actionUrl = form.attr('action');

        $.ajax({
            type: "POST",
            url: actionUrl,
            data: form.serialize(), // serializes the form's elements.
            success: function(data) {
                $('.technicians-dropdown select').each((i, selectElem) => {
                    var dropdown = Tags.getInstance(selectElem);
                    dropdown.setData(dropdown.getData().concat([{ 'value': data.id, 'label': data.name }]));
                    dropdown._config.allowNew = false;
                });
                $('#addUserModal').modal('hide');
            },
            error: (err) => {
                $('.form-error').text(err.responseJSON.error);
                $('#addUserModal #worksiteSubsitesForm .alert-danger').show();
            }
        });

        $('#confirmCreateModal').modal('hide');
    } 
}