import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    form;
    elementToShow;
    static values = {
        elementToHideSelector: String,
    }

    connect() {
        if($('.submit-message-container').length == 0)
            $('body').prepend(
                `<div class="submit-message-container"><div class="loader"></div></div>`
            ); 
        this.elementToShow = $('.submit-message-container');

        if(this.element.tagName.toLowerCase() == 'form') this.form = $(this.element);
        else if ($(this.element).find('form').length) this.form = $(this.element).find('form');
        else if($(this.element).parents('form').length) this.form = $(this.element).parents('form').first();
        else this.form = $('form');
        this.elementToShow.hide();
        this.form.on('submit', () => {
            $(document).css('cursor', 'wait');
            if(this.elementToHideSelectorValue) $(this.elementToHideSelectorValue).hide();
            this.elementToShow.show();
            this.elementToShow.css('display', 'flex');
        });
        $(window).on('unload', () => {
            this.elementToShow.hide();
        });
    }
}