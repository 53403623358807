import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    fileInput;

    connect() {
        let dropArea = $(this.element);
        this.fileInput = $(this.element).find('input[type="file"]').get(0);

        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => dropArea.on(eventName, this.preventDefaults));
        ['dragenter', 'dragover'].forEach(eventName => dropArea.on(eventName, this.highlight));
        ['dragleave', 'drop'].forEach(eventName => dropArea.on(eventName, this.unhighlight));
        ['drop'].forEach(eventName => dropArea.on(eventName, (e) => {
            this.fileInput.files = e.originalEvent.dataTransfer.files;
            this.handleFileUpload();
        }));
        dropArea.find('input[type="file"]').on('change', () => this.handleFileUpload());
    }

    handleFileUpload() {
        $(this.element).hide();
        setTimeout(() => {
            $('.form-map').trigger('submit');
        }, 500);
    }

    highlight() {
        $(this).addClass('highlight');
        return false;
    }
    
    unhighlight() {
        $(this).removeClass('highlight');
    }
    
    preventDefaults (e) {
        e.preventDefault();
        e.stopPropagation();
    }
}