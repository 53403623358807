import { Controller } from '@hotwired/stimulus';

export default class extends Controller {    
    goToUrl;

    connect() {
        $('.btn[data-bs-dismiss]').on('click', () => this.goToUrl = null);
        $('.btn-confirm').on('click', () => $.ajax(this.goToUrl, { method: 'DELETE', success: () => window.location.reload() }))
        $('.btn-delete-worksite').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.goToUrl = $(e.target).closest('a').attr('href');
            $('#deleteModal').modal('show');
        })
    }
}