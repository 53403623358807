import { Controller } from '@hotwired/stimulus';
import { RadialMenu } from '../js/radial-menu';
import { ScrollZoomer } from '../js/scroll-zoomer';
import { getCookie, setCookie } from '../js/cookies'
import { uid } from 'uid';
import Hammer from 'hammerjs'

const ghostImg = new Image();
ghostImg.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';

const switchModeTapDetector = new Hammer.Tap();

function waitForElement(querySelector, timeout){
    return new Promise((resolve, reject)=>{
        var timer = false;
        if(document.querySelectorAll(querySelector).length) return resolve();
        const observer = new MutationObserver(() => {
            if(document.querySelectorAll(querySelector).length){
            observer.disconnect();
            if(timer !== false) clearTimeout(timer);
            return resolve();
            }
        });
        observer.observe(document.body, {
            childList: true, 
            subtree: true
        });
        if(timeout) timer = setTimeout(()=>{
            observer.disconnect();
            reject();
        }, timeout);
    });
}

const createMapSymbolDefinition = (mapElement) => `
<div class="pole map-element" data-label-position="${mapElement.labelPosition}" data-id="${mapElement.id}"
    style="scale: ${mapElement.zoom}; translate: ${mapElement.coordinateX}px ${mapElement.coordinateY}px;">
    <div class="map-element-imgs" style="rotate: ${mapElement.angle}deg">
        <div class="rotater">
            <i class="far fa-dot-circle"></i>
        </div>
        <img class="symbol-img" id="${mapElement.id}" src="${mapElement.symbol.icon}" height="40"/>
    </div>
    <p style="background-color: ${mapElement.labelColor}">${mapElement.number}</p>
    <img class="resize-img" src="/build/images/resize_triangle.svg"/>
    <img class="move-img" src="/build/images/move_multi-arrow.svg"/>
</div>
`;

export default class extends Controller {
    mapView;
    tabView;
    mapContent;
    radialMenu;
    zoomer;
    activeElement;
    draggingMap = false;
    draggedElementPart = null;
    startFingerTouches = null;
    lastElementZoomSet = 1;
    lastElementTechnicianSet = null;
    windowZoomLevel = 5;
    mapOffset = { x: 0, y: 0 };
    hasTouchScreen = false;
    initialized = false;
    changesToSave = {};
    elementsConfigById = {};
    placingScreenshotZone = null;
    screenShotZone = {
        x: 0, y: 0,
        width: 0, height: 0
    }

    static values = {
        mapId: Number,
        elements: Array,
        elementTypes: Array,
        isEditable: Boolean,
    }
    
    connect() {
        if (document.documentElement.hasAttribute('data-turbo-preview')) {
          return;
        }
        this.mapView = $(this.element).find('#mapDisplay');
        this.mapContent = $(this.element).find('#mapContent');
        this.tabView = $(this.element).find('#tabDisplay');

        if(getCookie('map' + this.mapIdValue + '_scale')) this.windowZoomLevel = parseFloat(getCookie('map' + this.mapIdValue + '_scale'));
        if(getCookie('map' + this.mapIdValue + '_positionX')) this.mapOffset.x = parseFloat(getCookie('map' + this.mapIdValue + '_positionX'));
        if(getCookie('map' + this.mapIdValue + '_positionY')) this.mapOffset.y = parseFloat(getCookie('map' + this.mapIdValue + '_positionY'));

        this.initHeader();
        this.initFooter();

        waitForElement('#mapContent > object', 10000).then(() => {
            this.initMap();
        })
        this.detectTouchScreen();
        $(document).on('keyup', (e) => {
            if(e.key === "Escape" && this.activeElement != null) {
                $('.map-element-infos').trigger('submit');
            }
        });

        setInterval(() => this.saveChanges(), 3000);
    }

    saveChanges() {
        Object.entries(this.changesToSave).forEach((elementEntry) => {
            $.ajax('/worksite/pole' + (!elementEntry[0].startsWith('tmp_') ? '/' + elementEntry[0] : ''), {
                method: 'POST',
                data: elementEntry[1],
                success: (data) => {
                    if(elementEntry[0].startsWith('tmp_')) {
                        var mapSymbol = $(`.map-element[data-id="${elementEntry[0]}"]`);
                        mapSymbol.data('id', data);
                        mapSymbol.attr('data-id', data);
                        this.elementsConfigById[data] = this.elementsConfigById[elementEntry[0]];
                        this.elementsConfigById[data].id = data;
                        delete this.elementsConfigById[elementEntry[0]];
                    }
                }
            });
            delete this.changesToSave[elementEntry[0]];
        });
    }

    initHeader() {
        setTimeout(() => {
            $('.flash-success').fadeOut(5000);
        }, 5000);
    }

    initFooter() {
    }
    
    initMap() {
        this.radialMenu = this.createRadialMenu();
        this.zoomer = new ScrollZoomer('#mapDisplay', {
            minScale: 2,
            maxScale: 20,
            zoomSpeed: 0.25,
            onUpdate: (pos, scale) => {
                if(!this.initialized) return;
                this.mapOffset = pos;
                this.windowZoomLevel = scale;
                setCookie('map' + this.mapIdValue + '_positionX', pos.x)
                setCookie('map' + this.mapIdValue + '_positionY', pos.y)
                setCookie('map' + this.mapIdValue + '_scale', scale)
                if(this.radialMenu && this.radialMenu.isOpened) this.radialMenu.close();
                $('.menuHolder').css('left', 0);
                $('.menuHolder').css('top', 0);
            }
        });

        $('.map-element-infos [name="designatedTechnician"]').on('change', (e) => {
            this.lastElementTechnicianSet = e.target.value;
        });

        $('.map-element-infos').on('submit', (e) => {
            e.preventDefault();
            if($('.map-element.active').length) {
                if(!document.forms.mapElement.checkValidity()) {
                    document.forms.mapElement.reportValidity();
                    return false;
                }

                var mapSymbol = $('.map-element.active');
                var mapSymbolId = mapSymbol.data('id');
                var elem = this.elementsConfigById[mapSymbolId];
                elem.aDegager = $('.map-element-infos [name="aDegager"]').get(0).checked ? true : false;
                [
                    'trackNumber', 'number', 'type', 'labelColor', 'labelPosition',
                    'designatedTechnician', 'access', 'support', 'pkSupport'
                ].forEach((property) => elem[property] = $('.map-element-infos [name="' + property + '"]').val());
                elem.symbol = this.elementTypesValue.find((elemType) => elemType.value == elem.type);
                console.log(elem);
                this.removeElementPointFromMap(mapSymbol);
                let newMapSymbol = this.addElementPointToMap(elem);
                this.addElementForSave(mapSymbol);

                this.saveChanges();
                this.switchModeForMapSymbol(newMapSymbol);
            }
        });

        $('.map-element-infos .selectpicker').selectpicker();

        $('.delete-pole').on('click', () => {
            if($('.map-element.active').length) {
                var mapSymbol = $('.map-element.active');
                this.switchModeForMapSymbol(mapSymbol);
                this.deleteElem(mapSymbol.data('id'));
            }
        });

        setTimeout(() => {
            this.zoomer
                .init({ boundingRect: { origin: { x: 0, y: 0 }, width: $('#mapContent > object').width(), height: $('#mapContent > object').height() }})
                .addPanning({ actionsType : this.hasTouchScreen ? 'tap' : 'mouse' })
                .addZooming({ actionsType : this.hasTouchScreen ? 'tap' : 'mouse' })
                .setDefaults({ defaultOffset : this.mapOffset, defaultZoom: this.windowZoomLevel })
            this.initialized = true;
            
            this.elementsValue.forEach((elem) => this.addElementPointToMap({
                    id: elem.id,
                    type: elem.type,
                    symbol: this.elementTypesValue.find((elemType) => elemType.value == elem.type),
                    trackNumber: elem.trackNumber,
                    number: elem.number,
                    angle: elem.angle,
                    scale: elem.zoom ?? elem.scale,
                    access: elem.access,
                    support: elem.support,
                    pkSupport: elem.pkSupport,
                    x: elem.coordinateX ?? elem.x,
                    y: elem.coordinateY ?? elem.y,
                    installationType: elem.installationType,
                    aDegager: elem.aDegager,
                    labelColor: elem.labelColor,
                    labelPosition: elem.labelPosition,
                    designatedTechnician: elem.designatedTechnician,
                    historyLinesCount: elem.historyLinesCount
                })
            );
        }, 500);

        if(this.isEditableValue) {
            this.mapContent.on('mousedown', () => this.draggingMap = false);
            this.mapContent.on('mousemove', () => this.draggingMap = true);
            this.mapContent.on('mouseup', (e) => {
                if(this.draggingMap) return false;
                if(!this.mapView.hasClass('edition-mode') && e.target.id == this.mapContent.attr('id')) {
                    if(!this.radialMenu.isOpened && !this.placingScreenshotZone) {
                        this.radialMenu.open();
                        $('.menuHolder').css('left', e.targetTouches ? e.targetTouches[0].pageX : e.pageX);
                        $('.menuHolder').css('top', (e.targetTouches ? e.targetTouches[0].pageY : e.pageY) - this.mapView.get(0).offsetTop);
                    } else {
                        this.radialMenu.close();
                    }
                }
            });

        }
        this.attachScreenshotZoneEvents();

        this.mapView.on('mousemove', (e) => {
            if(this.activeElement == null) return null;
            else {
                var mapSymbol = $('.map-element[data-id="' + this.activeElement.id + '"]');
                var mapSymbolCenter = {
                    x: (mapSymbol.find('.symbol-img').get(0).getBoundingClientRect().left + mapSymbol.find('.symbol-img').get(0).getBoundingClientRect().right) / 2,
                    y: (mapSymbol.find('.symbol-img').get(0).getBoundingClientRect().top + mapSymbol.find('.symbol-img').get(0).getBoundingClientRect().bottom) / 2,
                }
                var mousePositionRelativeToSymbol = { x: (e.pageX || e.deltaX || 0) - mapSymbolCenter.x, y: (e.pageY || e.deltaY || 0) - mapSymbolCenter.y };
                // The greatest absolute value indicate on which side is the mouse relatively to the symbol
                if(Math.abs(mousePositionRelativeToSymbol.x) > Math.abs(mousePositionRelativeToSymbol.y)) {
                    if(mousePositionRelativeToSymbol.x < 0) mapSymbol.attr('data-show-label-carret', 'left');
                    else mapSymbol.attr('data-show-label-carret', 'right');
                } else {
                    if(mousePositionRelativeToSymbol.y < 0) mapSymbol.attr('data-show-label-carret', 'up');
                    else mapSymbol.attr('data-show-label-carret', 'down');
                }
            }
        });
    }

    attachScreenshotZoneEvents() {
        var screenShotZoneTag = $('#screenShotZone');
        $('#drawScreenshotZone').on('click', (e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
            $('#placeScreenshotModal').modal('show');
        });

        $('#placeScreenshotModal .btn-confirm').on('click', () => {
            this.placingScreenshotZone = 'firstPoint';
            $('#placeScreenshotModal').modal('hide');
            this.mapContent.css('cursor', 'crosshair');
        });

        this.mapContent.on('click', (e) => {
            if(this.placingScreenshotZone == 'firstPoint') {
                this.preventDefaults(e);
                var x = (e.pageX - this.mapContent.offset().left) / this.windowZoomLevel;
                var y = (e.pageY - this.mapContent.offset().top) / this.windowZoomLevel;
                this.screenShotZone.x = x;
                this.screenShotZone.y = y;
                screenShotZoneTag.css('translate', x + 'px ' + y + 'px');
                screenShotZoneTag.show();
                this.placingScreenshotZone = 'secondPoint';
            } else if(this.placingScreenshotZone == 'secondPoint') {
                this.preventDefaults(e);
                var x = (e.pageX - this.mapContent.offset().left) / this.windowZoomLevel;
                var y = (e.pageY - this.mapContent.offset().top) / this.windowZoomLevel;
                var x1 = Math.min(this.screenShotZone.x, x);
                var y1 = Math.min(this.screenShotZone.y, y);
                var x2 = Math.max(this.screenShotZone.x, x);
                var y2 = Math.max(this.screenShotZone.y, y);
                this.screenShotZone.x = x1;
                this.screenShotZone.y = y1;
                this.screenShotZone.width = x2 - x1;
                this.screenShotZone.height = y2 - y1;
                this.placingScreenshotZone = null;
                this.mapContent.css('cursor', 'unset');

                $.ajax({
                    method: 'PUT',
                    url: '/sub-site/' + this.mapIdValue + '/screenshotZone',
                    data: {
                        'sub_site_screenshot_zone_form[screenshotOffsetX]': -this.screenShotZone.x * this.windowZoomLevel,
                        'sub_site_screenshot_zone_form[screenshotOffsetY]': -this.screenShotZone.y * this.windowZoomLevel,
                        'sub_site_screenshot_zone_form[screenshotWidth]': this.screenShotZone.width * this.windowZoomLevel,
                        'sub_site_screenshot_zone_form[screenshotHeight]': this.screenShotZone.height * this.windowZoomLevel,
                        'sub_site_screenshot_zone_form[screenshotZoom]': this.windowZoomLevel,
                    }
                }).then(() => {
                    this.placingScreenshotZone = null;
                    screenShotZoneTag.hide();
                }).always(() => window.location.reload());
            }
        });

        this.mapContent.on('mousemove', (e) => {
            if(this.placingScreenshotZone == 'secondPoint') {
                var x = (e.pageX - this.mapContent.offset().left) / this.windowZoomLevel;
                var y = (e.pageY - this.mapContent.offset().top) / this.windowZoomLevel;
                var x1 = Math.min(this.screenShotZone.x, x);
                var y1 = Math.min(this.screenShotZone.y, y);
                var x2 = Math.max(this.screenShotZone.x, x);
                var y2 = Math.max(this.screenShotZone.y, y);
                screenShotZoneTag.css('translate', x1 + 'px ' + y1 + 'px');
                screenShotZoneTag.css('width', x2 - x1);
                screenShotZoneTag.css('height', y2 - y1);
            }
        })
    }

    detectTouchScreen() {
        var mQ = window.matchMedia && matchMedia("(pointer:coarse)");
        if (mQ && mQ.media === "(pointer:coarse)") {
            this.hasTouchScreen = !!mQ.matches;
        } else if ('orientation' in window) {
            this.hasTouchScreen = true; // deprecated, but good fallback
        } else {
            // Only as a last resort, fall back to user agent sniffing
            var UA = navigator.userAgent;
            this.hasTouchScreen = (
                /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
            );
        }
    }

    createRadialMenu() {
        if(this.radialMenu) this.mapView.find('.menuHolder').remove();
        return new RadialMenu({
            parent: this.mapView.get(0),
            size: 250,
            closeOnClick: true,
            onClick: (item) => $('.menuHolder').find('.menu').length && !$('.menuHolder').find('.menu').hasClass('inner') ? this.switchModeForMapSymbol(this.addElementPointToMap({
                'id': 'tmp_' + uid(),
                'symbol': this.elementTypesValue.find((elemType) => elemType.value == item.id),
                'type': item.id,
                'installationType': 0,
                'aDegager': false,
                'number': '',
                'trackNumber': '',
                'labelColor': '#FFFFFF',
                'labelPosition': 'down',
                'x': (parseInt($('.menuHolder').css('left')) - this.mapOffset.x) / this.windowZoomLevel,
                'y': (parseInt($('.menuHolder').css('top')) - this.mapOffset.y) / this.windowZoomLevel,
                'designatedTechnician': this.lastElementTechnicianSet,
                'angle': 0,
                'scale': this.lastElementZoomSet,
                'access': '',
                'support': '',
                'pkSupport': '',
                'historyLinesCount': 0
            })) : null,
            menuItems: this.elementTypesValue.map((elem) => { return { 'id': elem.value, 'title': elem.label, 'icon': elem.icon }; })
        });
    }

    addElementPointToMap(mapElement) {
        let newMapSymbol = $(createMapSymbolDefinition(mapElement));
        let mapSymbolManager = new Hammer.Manager(newMapSymbol.find('.map-element-imgs').get(0)).add([switchModeTapDetector]);
        let mapSymbolEngine = new ScrollZoomer(this.mapContent, {
            contentElem: newMapSymbol,
            minScale: 0.2,
            maxScale: 1000000,
            onUpdate: (pos, scale, angle) => {
                var mapSymbolId = newMapSymbol.data('id');
                this.elementsConfigById[mapSymbolId].x = pos.x;
                this.elementsConfigById[mapSymbolId].y = pos.y;
                this.elementsConfigById[mapSymbolId].scale = scale;
                this.elementsConfigById[mapSymbolId].angle = angle;
                this.addElementForSave(newMapSymbol);
            },
            onChangeEnd: (t) => this.saveChanges()
        });
        mapSymbolEngine.pos = { x: mapElement.x, y: mapElement.y };
        this.elementsConfigById[mapElement.id] = mapElement;
        mapElement.mapSymbolManager = mapSymbolManager;
        mapElement.engine = mapSymbolEngine;
            newMapSymbol.find('.symbol-img').on('load', () => {
                this.attachElementNodeEvents(newMapSymbol);
                mapSymbolEngine
                    .init({ boundingElem: this.mapContent.find('object') })
                    .addPanning({ draggableElements: [newMapSymbol.find('.symbol-img'), newMapSymbol.find('.move-img'), newMapSymbol.find('p')], actionsType: !this.hasTouchScreen ? 'mouse' : 'tap' })
                    .addScaling(!this.hasTouchScreen ? newMapSymbol.find('.resize-img') : $('#subSiteContainer'), { actionsType: !this.hasTouchScreen ? 'mouse' : 'tap' })
                    .addRotating(newMapSymbol.find('.rotater i'), newMapSymbol.find('.map-element-imgs'), { actionsType: !this.hasTouchScreen ? 'mouse' : 'tap' })
                    .setDefaults({ defaultOffset : { x: mapElement.x, y: mapElement.y }, defaultZoom: mapElement.scale, defaultAngle: mapElement.angle })
                    .off();
                if(this.isEditableValue) {
                    if(newMapSymbol.hasClass('active')) mapSymbolEngine.on();
                } else newMapSymbol.css('pointer-events', 'none');
            });

        this.mapContent.append(newMapSymbol);
        if(this.isEditableValue)
            mapSymbolManager.on('tap', (e) => this.switchModeForMapSymbol($(e.target).parentsUntil('#mapContent').last()));

        newMapSymbol.on('panTo', () => {
            this.zoomer.panTo({
                x: this.elementsConfigById[mapElement.id].x * this.windowZoomLevel - ($(this.mapView).innerWidth() + $(newMapSymbol).innerWidth()) / 2,
                y: this.elementsConfigById[mapElement.id].y * this.windowZoomLevel - ($(this.mapView).innerHeight() - $(newMapSymbol).innerHeight()) / 2
            })
        });

        return newMapSymbol;
    }

    removeElementPointFromMap(mapSymbol) {
        mapSymbol.remove();
    }

    attachElementNodeEvents(mapSymbol) {
        mapSymbol.find('p')
            .on('click', (e) => {
                if(!mapSymbol.hasClass('active')) {
                    this.switchModeForMapSymbol(mapSymbol);
                }
            })
        mapSymbol.on('keypress', (e) => { if(e.keycode == 13) e.preventDefault(); })
        let mapSymbolId = mapSymbol.data('id');
        if(!this.hasTouchScreen) {
            // drag (PCs)
            mapSymbol.find('img').on('dragstart', (e) => {
                if(this.activeElement == null || this.activeElement.id != mapSymbolId) return false;
                this.draggedElementPart = $(e.target); e.originalEvent.dataTransfer.setDragImage(ghostImg, 0, 0);
            });
            mapSymbol.find('img').on('drop', () => setTimeout(() => this.draggedElementPart = null, 50));
        } else {
        }
    }

    switchModeForMapSymbol(mapSymbol) {
        // Close the radial menu just in case
        if(this.radialMenu.isOpened)
            this.radialMenu = this.createRadialMenu();
        // Deactivate previous elements
        $('.rotater, .resize-img, .move-img').hide();
        $('.map-element').each((_, element) => {
            this.elementsConfigById[$(element).data('id')].engine.off();
        });
        
        this.mapView.removeClass('edition-mode');
        if(this.activeElement == null || this.activeElement.id != mapSymbol.data('id')) { // We clicked on a new item
            this.mapView.addClass('edition-mode');
            $('.map-element.active').removeClass('active');
            mapSymbol.addClass('active');
            this.zoomer.off();
            this.activeElement = this.elementsConfigById[mapSymbol.data('id')];
            this.activeElement.engine.on();
            $('.map-element-infos [name="trackNumber"]').val(this.activeElement.trackNumber);
            $('.map-element-infos [name="number"]').val(mapSymbol.find('p').text());
            $('.map-element-infos [name="type"]').selectpicker('val', this.activeElement.type + '');
            $('.map-element-infos [name="labelColor"]').selectpicker('val', this.activeElement.labelColor);
            $('.map-element-infos [name="labelPosition"]').selectpicker('val', this.activeElement.labelPosition);
            $('.map-element-infos [name="designatedTechnician"]').selectpicker('val', this.activeElement.designatedTechnician);
            $('.map-element-infos [name="access"]').val(this.activeElement.access);
            $('.map-element-infos [name="support"]').val(this.activeElement.support);
            $('.map-element-infos [name="pkSupport"]').val(this.activeElement.pkSupport);
            $('.map-element-infos [name="aDegager"]').get(0).checked = this.activeElement.aDegager;
            if(this.activeElement.historyLinesCount > 0) $('.map-element-infos .delete-pole').hide();
            else $('.map-element-infos .delete-pole').show();

            if(!this.hasTouchScreen) mapSymbol.find('.rotater, .resize-img').show();
            else mapSymbol.find('.move-img').show();
        } else { // We clicked on the already selectedItem item
            $('.map-element.active').trigger('blur');
            $('.map-element.active').removeClass('active');
            this.zoomer.on();
            this.activeElement = null;
        }
    }

    addElementForSave(mapSymbol) {
        var mapSymbolId = mapSymbol.data('id');
        let elementConfig = this.elementsConfigById[mapSymbolId];
        this.changesToSave[mapSymbolId] = { 
            'pole_form[subSite]': this.mapIdValue,
            'pole_form[zoom]': elementConfig.scale,
            'pole_form[coordinateX]': elementConfig.x,
            'pole_form[coordinateY]': elementConfig.y,
        };
        [
            'type', 'installationType', 'number', 'trackNumber',
            'labelColor', 'labelPosition', 'designatedTechnician',
            'angle', 'access', 'pkSupport',  'support'
        ].forEach((property) => this.changesToSave[mapSymbolId]['pole_form[' + property + ']'] = elementConfig[property]);
        if(elementConfig.aDegager) this.changesToSave[mapSymbolId]['pole_form[aDegager]'] = elementConfig.aDegager;
    }

    deleteElem(idElement) {
        if(confirm('Voulez vous vraiment supprimer cette perche ?')) {
            var callbackDelete = () => {
                $('[data-id="' + idElement + '"]').remove();
                delete this.elementsConfigById[idElement];
            };
            if(!(idElement + '').startsWith('tmp_')) {
                $.ajax('/worksite/pole/' + idElement, {
                    method: 'DELETE',
                    success: () => callbackDelete()
                });
            } else {
                callbackDelete();
            }
        }
    }

    preventDefaults (e) {
        if(e.cancelable)
            e.preventDefault();
        e.stopPropagation();
    }
}