import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    selectedPoleId;

    connect() {
        $('form[name="sub_site_poles_form"]').attr('id', 'subsitePolesForm');

        $('.field-collection-delete-button').on('click', (e) => this.onDeleteRow(e));
        $('#deleteModal .btn-confirm').on('click', () => this.deleteRow());
        $('.pole.table-sm-item').on('click', (e) => $(e.target).closest('.pole').toggleClass('expand'))
    }

    onDeleteRow(event) {
        this.selectedPoleId = $(event.target).parentsUntil('tbody').last().data('id');
        if(this.selectedPoleId < 0) {
            $('.pole-line[data-id="' + this.selectedPoleId + '"]').remove();
            $('#deleteModal').modal('hide');
        } else $('#deleteModal').modal('show');
    }

    deleteRow() {
        $('.pole-line[data-id="' + this.selectedPoleId + '"]').addClass('disabled');
        $('.pole-line[data-id="' + this.selectedPoleId + '"]').find('input, select, button').attr('disabled', 'disabled');
        $('#deleteModal').modal('hide');
    }
}