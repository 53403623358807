import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        poles: Array,
        mercureUrl: String,
        polingAutorizationDateTime: String,
    }

    connect() {
        $('.pole-summary').on('click', (e) => $(e.target).parentsUntil('.poles-statuses-banner').last().toggleClass('showList'))
        $('.pole-status').on('click', function() {
            var targetPole = $('.pole[data-id="' + $(this).data('id') + '"]');
            targetPole.trigger('panTo');
            $(targetPole).addClass('highlight');
            setTimeout(() => {
                $(targetPole).removeClass('highlight');
            }, 1500);
        });
        if(this.polingAutorizationDateTimeValue) this.subscribeToSensors();
        this.sortStatusLists();
        $('.pole-status').each((i, poleStatusTag) => {
            if($(poleStatusTag).data('sensorId')) {
                if($(poleStatusTag).data('sync-state') !== undefined)
                    this.setSensorState(poleStatusTag, { status: $(poleStatusTag).data('sync-state'), aDegager: null })
                else
                    this.setSensorState(poleStatusTag, { status: 4, aDegager: null, manual: true })
            }
        });
    }

    sortStatusLists() {
        $('.status-poles').each((i, statusPolesTag) => {
            let lengthStatusList = $(statusPolesTag).find('.pole-status').length;
            $(statusPolesTag).find('.poles-count').text(lengthStatusList);
            $(statusPolesTag).find('.pole-status').sort((tagA, tagB) =>
                $(tagA).data('track-number') == $(tagB).data('track-number') && $(tagA).data('track-number') != null
                    ? ('' + $(tagA).data('number')).localeCompare($(tagB).data('number'))
                    : ('' + $(tagA).data('track-number')).localeCompare($(tagB).data('track-number'))
            ).each((j, statusPoleTag) => $(statusPoleTag).css('top', ((j - lengthStatusList) * 45) + 'px'));
        })
    }

    setSensorState(targetPoleTag, value) {
        const targetPole = $(targetPoleTag);
        let apiStatus = value.status, manualStatus = targetPole.data('state');
        let status = apiStatus;
        let poleId = targetPole.data('id');

        // if(value.datetime && Date.parse(this.polingAutorizationDateTime) > Date.parse(value.datetime)) return;
    
        if(manualStatus != apiStatus) {
            if(!value.manual) {
                targetPole.addClass('desync');
                $('.pole.pole-line[data-id="' + poleId + '"]').addClass('desync');
            }
            if(manualStatus == 1 || apiStatus == 1) status = 1;
            else if(apiStatus === 3) status = 0;
        } else if(!value.manual) {
            targetPole.removeClass('desync');
            $('.pole.pole-line[data-id="' + poleId + '"]').removeClass('desync');
        }

        if(apiStatus < 4) {
            if(value.aDegager !== null && value.aDegager !== undefined)
                $(targetPole).find('.a-degager-notice').css('visibility', value.aDegager ? 'visible' : 'hidden')
            $('.pole.pole-line[data-id="' + poleId + '"] .pole-status').addClass('updated');
            targetPole.addClass('updated');
        } else {
            targetPole.removeClass('updated');
            $('.pole.pole-line[data-id="' + poleId + '"] .pole-status').removeClass('updated');
            status = targetPole.data('state') === 3 ? 3 : targetPole.data('state') === 1 ? 1 : 0;
        }

        if(targetPole.parents('.status-poles.state-' + status).length === 0) {
            targetPole.detach();
            $('.status-poles.state-' + status).prepend(targetPole);
            this.sortStatusLists();
        }

        if(!value.manual) {
            $('.pole.pole-line[data-id="' + poleId + '"]').each((i, elem) => {
                $(elem).removeClass('pole-status-0 pole-status-1 pole-status-2 pole-status-3 pole-status-4');
                $(elem).addClass('pole-status-' + status);
            });
        }
    }

    subscribeToSensors() {
        var sensorIds = this.polesValue.map((p) => p.sensorId);
        (new Set(sensorIds)).forEach((sensorId) => {
            if(sensorId) {
                const es = new EventSource(this.mercureUrlValue + '?topic=' + encodeURIComponent('/sensor/' + sensorId));
                es.onmessage = e => {
                    // Will be called every time an update is published by the server
                    const targetPoles = $('.pole-status[data-sensor-id="' + sensorId + '"]').toArray();
                    targetPoles.forEach((targetPoleTag) => {
                        this.setSensorState(targetPoleTag, JSON.parse(e.data));
                    })
                }
            }
        });
    }
}