import { Controller } from '@hotwired/stimulus';
import { Html5Qrcode, Html5QrcodeScannerState } from "html5-qrcode";

export default class extends Controller {
    qrCodeReaders = {}
    cameraId;
    openedModal;
    subSiteStatusChangeUrl;

    static values = {
        isSiteManagerConnected: Boolean,
        subSiteId: Number
    }
    
    connect() {
        this.screenShotZone = $('#screenShotZone');
        this.initInstallationModal();
        this.initSubSiteStatusButtons();

        // Automatically open the pdf if asked in the query
        let params = new URL(document.location.toString()).searchParams;
        let openPdf = params.get("openPdf");
        if(openPdf) {
            $('#downloadSubSite').get(0).click();
        }
    }

    onScanSuccess(decodedText, decodedResult) {
        // handle the scanned code as you like, for example:
        console.log(`Code matched = ${decodedText}`, decodedResult);
        this.openedModal.find('input[name="sensorId"]').val(decodedText);
        $('.btn-qrcode-scan-clear').trigger('click');
    }

    onScanFailure(error) {
        // handle scan failure, usually better to ignore and keep scanning.
        // for example:
        // console.warn(`Code scan error = ${error}`);
    }

    initInstallationModal() {
        this.initQRCodeScanner();
        $('.pole-perche-btn').on('click', () => this.showInstallationModal('poseModal'));
        $('.remove-perche-btn').on('click', () => this.showInstallationModal('deposeModal'));
        $('.installation-modal select[name="pole"]').on('change', (e) => {
            var aDegager = $(e.target).find('option:selected').data('a-degager');
            var sensorId = $(e.target).find('option:selected').data('sensor-id');
            this.openedModal.find('.danger-text').css('display', aDegager ? 'flex' : 'none');
            this.openedModal.find('#sensorIdInput').val(sensorId);
        });

        $('.installation-modal .btn-confirm').on('click', (e) => {
            const [poleId, sensorId, installationType, time] = ['pole', 'sensorId', 'installationType', 'timeInstallation'].map((name) => {
                var field = this.openedModal.find('[name="' + name + '"]')
                field.removeClass('is-valid').removeClass('is-invalid');
                if(!field.val() && name != 'sensorId') field.addClass('is-invalid');
                else field.addClass('is-valid');
                return field.val();
            });
            if(!poleId || !installationType || !time) return false;

            // Show loader
            $(document).css('cursor', 'wait');
            $('.submit-message-container').show();
            $('.submit-message-container').css('display', 'flex');

            const technicianName = this.openedModal.find('[name="technician"]').length ? this.openedModal.find('[name="technician"]').val() : null;
            this.openedModal.modal('hide');
            var data = {
                'poling_form[pole]': poleId,
                'poling_form[sensorId]': sensorId,
                'poling_form[technicianName]': technicianName,
                'poling_form[polingType]': installationType,
                'poling_form[timeAction]': time
            };
            if(this.openedModal.find('[name="aDegager"]').get(0).checked)
                data['poling_form[aDegager]'] = 1;

            $.ajax('/worksite/poling', {
                method: 'POST',
                data: data,
                success: () => window.location.reload(),
                error: () => window.location.reload()
            });
        });
        if(!this.isSiteManagerConnectedValue) {
            $('.installation-modal input[type="time"]').on('change', (e) => {
                var valDate = new Date();
                valDate.setHours($(e.target).val().split(':')[0], $(e.target).val().split(':')[1]);
                $(e.target).val(new Date(
                    Math.min(new Date().getTime(), Math.max(new Date(new Date().getTime() - 10 * 60 * 1000).getTime(),valDate.getTime()))
                ).toLocaleTimeString().substring(0, 5))
            });
        }
    }

    initQRCodeScanner() {
        $('.installation-modal').each((i, modalTag) => {
            if($(modalTag).find('#qrcodeReader-' + modalTag.id).length)
                this.qrCodeReaders[modalTag.id] = new Html5Qrcode('qrcodeReader-' + modalTag.id);
            $(modalTag).on('hidden.bs.modal', () => this.closeQRCodeScanner());
        })
        $('.btn-qrcode-scan').on('click', () => {
            let modalId = this.openedModal.attr('id');
            if(!this.qrCodeReaders[modalId]) return;
            $('.btn-qrcode-scan').hide();
            $('.btn-qrcode-scan-clear').css('display', 'flex');
            if(!this.cameraId) {
                Html5Qrcode.getCameras().then(devices => {
                    if (devices && devices.length) {
                        this.cameraId = devices[0].id;
                        this.qrCodeReaders[modalId].start(this.cameraId, {
                            fps: 10,
                            qrbox: { width: 200, height: 200 },
                            videoConstraints: { facingMode: { ideal: "environment" } }
                        }, (decodedText, decodedResult) => this.onScanSuccess(decodedText, decodedResult), this.onScanFailure);
                    }
                }).catch(() => {
                    $('.btn-qrcode-scan').css('display', 'flex');
                    $('.btn-qrcode-scan-clear').hide();
                });
            } else
                this.qrCodeReaders[modalId].start(this.cameraId, {
                    fps: 10,
                    qrbox: { width: 200, height: 200 },
                    videoConstraints: { facingMode: { ideal: "environment" } }
                }, (decodedText, decodedResult) => this.onScanSuccess(decodedText, decodedResult), this.onScanFailure);
        });
        $('.btn-qrcode-scan-clear').on('click', () => this.closeQRCodeScanner());

    }

    closeQRCodeScanner() {
        $('.btn-qrcode-scan').css('display', 'flex');
        $('.btn-qrcode-scan-clear').hide();
        let modalId = this.openedModal.attr('id');
        if(!this.qrCodeReaders[modalId]) return;
        if(this.qrCodeReaders[modalId].getState() == Html5QrcodeScannerState.SCANNING)
            this.qrCodeReaders[modalId].stop().then(()=> this.qrCodeReaders[modalId].clear());
    }

    showInstallationModal(idModal) {
        $.get('/sub-site/' + this.subSiteIdValue + '/status').then((subSiteStatus) => {
            if(subSiteStatus < 4) {
                this.openedModal = $('#' + idModal);
                this.openedModal.find('.selectpicker').selectpicker();

                this.openedModal.find('.status-warning').text('');
                if(idModal === 'poseModal') {
                    this.openedModal.find('[name="installationType"]').val(1);
                    this.openedModal.find('#aDegager').show();
                    this.openedModal.find('.modal-title').text('Pose des protections électriques');
                    this.openedModal.find('.modal-header .material-symbols-outlined').text('arrow_downward');
                    if(subSiteStatus == 3) this.openedModal.find('.status-warning').text('Attention: la nuit est au statut "Retraits en cours"');
                } else {
                    this.openedModal.find('[name="installationType"]').val(3);
                    this.openedModal.find('#aDegager').hide();
                    this.openedModal.find('.modal-title').text('Retrait des protections électriques');
                    this.openedModal.find('.modal-header .material-symbols-outlined').text('arrow_upward');
                    if(subSiteStatus == 1) this.openedModal.find('.status-warning').text('Attention: la nuit est au statut "Poses en cours"');
                }
                if(subSiteStatus == 2) this.openedModal.find('.status-warning').text('Attention: la "nuit" est au statut "Poses réalisées"');
                this.openedModal.find('.errorMessage').css('visibility', 'hidden');
                this.openedModal.find('input[type="time"]').val(new Date().toLocaleTimeString().substring(0, 5));
                this.openedModal.modal('show');
            } else {
                window.location.reload();
            }
        })
    }

    initSubSiteStatusButtons() {
        ['#confirmSubSiteCreation', '#polingValidation', '#startRemovalPhase', '#confirmClose'].forEach((btnId) => {
            $('.change-status').filter(btnId).on('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                this.subSiteStatusChangeUrl = $(e.target).closest('button').data('href');
                $(btnId + 'Modal').modal('show');
                return false;
            });
            $(btnId + 'Modal .btn-confirm').on('click', () => {
                if(!this.subSiteStatusChangeUrl) return;
                $.ajax({
                    method: 'PUT',
                    url: this.subSiteStatusChangeUrl
                })
                .then(() => btnId == '#confirmClose'
                    ? window.location.href = window.location.protocol + "//" + window.location.host + window.location.pathname + '?openPdf=1'
                    : window.location.reload() 
                )//.catch(() => window.location.reload());
            });
        });
    } 
}