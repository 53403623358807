import Hammer from 'hammerjs';
import { get } from 'jquery';

export class ScrollZoomer {
    viewportElem;
    contentElem;
    draggableElements;
    rotatingElem;
    scalingElem;
    zoomingElem;
    boundingRect;
    boundingElem;
    minScale;
    maxScale;
    zoomSpeed;
    onUpdate;
    pos = {x:0,y:0};
    scale = 1;
    angle = 0;
    _on = true;
    _manualUpdate = false;
    _panDetector = new Hammer.Pan();
    _pinchDetector = new Hammer.Pinch();
    _rotateDetector = new Hammer.Rotate();
    _elemPanningManagers = [];
    _elemPinchingManager;
    _zoom_content = {x:0,y:0};
    _zoom_point = {x:0,y:0};
    _last_mouse_position = {x:0,y:0};
    _last_scale = null;
    _drag_started = 0;
    _rotating_started = 0;
    _scaling_started = 0;

    constructor(viewportElem, {contentElem, boundingRect, boundingElem, minScale = 1, maxScale = 10, zoomSpeed = 1, onUpdate, onChangeEnd}) {
        this.viewportElem = typeof viewportElem === 'string' || viewportElem instanceof String ? $(viewportElem) : viewportElem;
        this.boundingRect = boundingRect;
        this.boundingElem = boundingElem;
        this.contentElem = contentElem;
        this.minScale = minScale;
        this.maxScale = maxScale;
        this.zoomSpeed = zoomSpeed;
        this.onUpdate = onUpdate;
        this.onChangeEnd = onChangeEnd;
        this._panDetector.recognizeWith(this._pinchDetector);
    }

	init({ boundingRect, boundingElem } = {}) {
        this.boundingRect = boundingRect || this.boundingRect;
        this.boundingElem = boundingElem || this.boundingElem;
        if(!this.boundingRect && !this.boundingElem) this.boundingElem = this.viewportElem;
        if(!this.contentElem) this.contentElem = this.viewportElem.children().first();
        // this.contentElem.css('transform-origin','0 0')
        this.size = {w:this.contentElem.width(),h:this.contentElem.height()};

		return this;
	}

    setDefaults({ defaultOffset, defaultZoom, defaultAngle }) {
        if(defaultOffset) this.pos = defaultOffset;
        if(defaultZoom) this.scale = defaultZoom;
        if(defaultAngle) this.angle = defaultAngle;
        this._last_scale = this.scale;
        this._manualUpdate = true;
        this.update();
        this._manualUpdate = false;

        return this;
    }

    addPanning({ draggableElements, actionsType = 'mouse' } = { actionsType: 'mouse' }) {
        this.draggableElements = draggableElements || [this.contentElem];
        switch (actionsType) {
            case 'mouse':
                this.viewportElem.on('mousemove', (e) => this._moved(e));
                break;
        }

        this.draggableElements.forEach((draggableElement) => {
            var curr_tranform = draggableElement.css('transition');
            var onRelease = (element) => {
                if(this.onChangeEnd && this._drag_started == 1) this.onChangeEnd('pan');
                this._drag_started = 0;
                element.css({'cursor':'default', 'transition': curr_tranform});
            }
            switch (actionsType) {
                case 'mouse':
                    draggableElement.on('mousedown', (ev) => {
                        this._drag_started = 1;
                        draggableElement.css({'cursor':'move', 'transition': 'translate 0s'});
                        /* Save mouse position */
                        this._last_mouse_position = { x: ev.pageX, y: ev.pageY};
                    });

                    draggableElement.on('mouseup', (e) => onRelease(draggableElement));
                    this.viewportElem.on('mouseup', (e) => onRelease(draggableElement));
                    break;
                case 'tap':
                    var elemPanningManager = new Hammer.Manager(draggableElement.get(0));
                    elemPanningManager.add(this._panDetector);
                    this.viewportElem.on('touchmove', function (event) {
                        if (event.scale !== 1) { event.preventDefault(); }
                      }, false);
                    elemPanningManager.on('panstart', (ev) =>  {
                        this._drag_started = 1;
                        draggableElement.css({'cursor':'move', 'transition': 'translate 0s'});
                        /* Save mouse position */
                        this._last_mouse_position = { x: ev.deltaX, y: ev.deltaY};
                    });
                    elemPanningManager.on('panmove', (e) => this._moved(e));
                    elemPanningManager.on('panend', () => onRelease(draggableElement));
                    this._elemPanningManagers.push(elemPanningManager);
                    break;
            }
        });

        return this;
    }

    addZooming({ zoomingElem, actionsType = 'mouse' } = { actionsType: 'mouse' }) {
        this.zoomingElem = zoomingElem || this.contentElem;
        this._last_scale = this.scale;
        switch (actionsType) {
            case 'mouse':
                this.contentElem.on("wheel DOMMouseScroll", (e) => this._scrolled(e))
                break;
            case 'tap':
                if(!this._elemPinchingManager)
                    this._elemPinchingManager = new Hammer.Manager(this.contentElem.get(0));
                else
                    this._rotateDetector.recognizeWith(this._pinchDetector);
                this._elemPinchingManager.add(this._pinchDetector);
                this._elemPinchingManager.on('pinchstart', (e) => {
                    this._last_scale = e.scale;
                });
                this._elemPinchingManager.on('pinchmove', (e) => {
                    if(e.target.id == this.zoomingElem.attr('id')) this._scrolled(e)
                });
                this._elemPinchingManager.on('pinchend', (e) => {
                    this._last_scale = null
                    if(this.onChangeEnd) this.onChangeEnd('zoom');
                });
                break;
        }

        return this;
    }

    addRotating(rotatingElem, rotatedElement, { actionsType = 'mouse', defaultAngle = 0 }) {
        this.rotatingElem = rotatingElem;
        this.rotatedElement = rotatedElement || this.contentElem;
        this.rotatingElem.attr('rotater', '1')
        this.angle = defaultAngle;
        this.contentElem.css('transform-origin','0 0')
        switch (actionsType) {
            case 'mouse':
                this.viewportElem.on('mousemove', (e) => this._rotated(e));
                this.rotatingElem.on('mousedown', (ev) => {
                    if(!$(ev.target).attr('rotater')) return;
                    this._rotating_started = 1;
                    this.contentElem.addClass('rotating');
                    this.rotatingElem.css('cursor', 'move');
                });

                var onRelease = () => {
                    if(this.onChangeEnd && this._rotating_started == 1) this.onChangeEnd('rotation');
                    this._rotating_started = 0;
                    this.contentElem.removeClass('rotating');
                    this.rotatingElem.css({'cursor':'default'});
                };
                
                this.rotatingElem.on('mouseup', () => onRelease());
                this.viewportElem.on('mouseup', () => onRelease());
                break;
            case 'tap':
                if(!this._elemPinchingManager)
                    this._elemPinchingManager = new Hammer.Manager(this.contentElem.get(0));
                else
                    this._pinchDetector.recognizeWith(this._rotateDetector);
                this._elemPinchingManager.add(this._rotateDetector);
                this._elemPinchingManager.on('rotatestart', (e) => {
                    this._rotating_started = 1;
                    this._last_angle = this.angle;
                });
                this._elemPinchingManager.on('rotatemove', (ev) => {
                    if(!this._on) return;
                    this.angle = (this._last_angle + ev.angle) % 360;
                    this.update()
                });
                this._elemPinchingManager.on('rotateend', () => {
                    if(this.onChangeEnd && this._rotating_started == 1) this.onChangeEnd('rotation');
                    this._rotating_started = 0;
                })
                break;
        }

        return this;
    }

    addScaling(scalingElem, { actionsType = 'mouse', cornerType = 'UP_RIGHT', defaultZoom = 1 } = { actionsType: 'mouse', cornerType: 'UP_RIGHT', defaultZoom: 1 }) {
        this.scalingElem = scalingElem;
        this.scale = defaultZoom;
        this.contentElem.css('transform-origin','0 0')
        switch (actionsType) {
            case 'mouse':
                this.viewportElem.on('mousemove', (e) => this._scaleMoved(e, cornerType));
                this.scalingElem.on('mousedown', (ev) => {
                    this._scaling_started = 1;
                    this.scalingElem.css('cursor', 'move');
                    /* Save mouse position */
                    this._last_mouse_position = { x: ev.pageX, y: ev.pageY};
                });

                var onRelease = () => {
                    if(this.onChangeEnd && this._scaling_started == 1) this.onChangeEnd('scale');
                    this._scaling_started = 0;
                    this.scalingElem.css({'cursor':'default'});
                };
                
                this.scalingElem.on('mouseup', () => onRelease());
                this.viewportElem.on('mouseup', () => onRelease());
                break;
            case 'tap':
                if(!this._elemPinchingManager)
                    this._elemPinchingManager = new Hammer.Manager(this.scalingElem.get(0));
                else
                    this._rotateDetector.recognizeWith(this._pinchDetector);
                this._elemPinchingManager.add(this._pinchDetector);
                this._elemPinchingManager.on('pinchstart', (e) => {
                    this._scaling_started = 1;
                    this._last_scale = parseFloat(this.scale);
                });
                this._elemPinchingManager.on('pinchmove', (ev) => {
                    if(!this._on) return;
                    this.scale = this._last_scale * (ev.scale - (ev.scale - 1) / 2);
                    this.update()
                });
                this._elemPinchingManager.on('pinchend', () => {
                    if(this.onChangeEnd && this._scaling_started == 1) this.onChangeEnd('scale');
                    this._scaling_started = 0;
                })
                break;
        }

        return this;
    }

    on() {
        this._on = true;
    }

    off() {
        this._on = false;
    }

    panTo(newPosition) {
        var oldOnVal = this._on;
        var oldTransitionVal = this.contentElem.css('transition');
        this.on();
        this._drag_started = 1;
        this.pos = { x: 0, y: 0 };
        this.contentElem.css('transition', 'translate 0.5s ease 0s');
        this._last_mouse_position = newPosition
        this._moved({ pageX: 0, pageY: 0 });
        setTimeout(() => this.contentElem.css('transition', oldTransitionVal), 500);
        this._drag_started = 0;
        if(!oldOnVal) this.off();
    }

    _scrolled(e) {
        if(!this._on) return;
        
        var offset = this.viewportElem.offset()
        if(e.center) {
            this._zoom_point.x = e.center.x - offset.left;
            this._zoom_point.y = e.center.y - offset.top;
        } else {
            this._zoom_point.x = e.pageX - offset.left;
            this._zoom_point.y = e.pageY - offset.top;
        }

        // determine the point on where the slide is zoomed in
        this._zoom_content.x = (this._zoom_point.x - this.pos.x) / this.scale;
        this._zoom_content.y = (this._zoom_point.y - this.pos.y) / this.scale;
        
        if(e.scale !== undefined) {
            this.scale += (e.scale - this._last_scale) * this.scale;
            this._last_scale = e.scale;
        } else {
            var delta = e.delta || e.originalEvent.wheelDelta;
            if (delta === undefined) {
                //we are on firefox
                delta = e.originalEvent.detail;
            }
            delta = Math.max(-1,Math.min(1, delta)); // cap the delta to [-1,1] for cross browser consistency
            this.scale += delta * this.zoomSpeed * this.scale;
            // apply zoom
        }
        this.scale = Math.max(this.minScale, Math.min(this.maxScale, this.scale));
        // calculate x and y based on zoom
        this.pos.x = -this._zoom_content.x * this.scale + this._zoom_point.x;
        this.pos.y = -this._zoom_content.y * this.scale + this._zoom_point.y;

        this.update()
    }

    _moved(ev) {
        if(!this._on || this._drag_started != 1) return;

        this.scale = parseFloat(this.contentElem.css('scale'));
        var viewportScale = this.viewportElem.css('scale') != 'none' ? this.viewportElem.css('scale') : 1;        
        var current_mouse_position = { x: ev.pageX || ev.deltaX || 0, y: ev.pageY || ev.deltaY || 0 };
        var change_x = current_mouse_position.x - this._last_mouse_position.x;
        var change_y = current_mouse_position.y - this._last_mouse_position.y;

        /* Save mouse position */
        this._last_mouse_position = current_mouse_position;
        //Add the position change
        this.pos.x += change_x / viewportScale;
        this.pos.y += change_y / viewportScale;

        this.update();
    }

    _rotated(ev) {
        if(!this._on || this._rotating_started != 1) return;

        this.angle = parseFloat((this.contentElem.css('angle') ? this.contentElem.css('angle') : '0deg').replace('deg', ''));

        const elementRect = this.contentElem.get(0).getBoundingClientRect();
        const elementCenter = { x: (elementRect.left + elementRect.right) / 2, y: (elementRect.top + elementRect.bottom) / 2 }
        var current_mouse_position = { x: ev.pageX || ev.deltaX || 0, y: ev.pageY || ev.deltaY || 0 };

        this.angle = this.constructor.computeAngleABC(
            { x: 0, y: this.contentElem.height() / 2 },
            { x: current_mouse_position.x - elementCenter.x, y: elementCenter.y - current_mouse_position.y }
        );

        this.update();
    }

    _scaleMoved(ev, cornerType) {
        if(!this._on || this._scaling_started != 1) return;

        var current_mouse_position = { x: ev.pageX || ev.deltaX || 0, y: ev.pageY || ev.deltaY || 0 };
        const elementRect = this.contentElem.get(0).getBoundingClientRect();
        const resizerRect = this.scalingElem.get(0).getBoundingClientRect();
        const elementCenter = { x: (elementRect.left + elementRect.right) / 2, y: (elementRect.top + elementRect.bottom) / 2 }
        var elementDiagonalLength = Math.sqrt(
                Math.pow((elementRect.left + elementRect.right) / 2 - (resizerRect.left + 3 * resizerRect.right) / 4, 2)
                + Math.pow((elementRect.top + elementRect.bottom) / 2 - (3 * resizerRect.top + resizerRect.bottom) / 4, 2)
            );
        var rotateAngle = 0;
        switch(cornerType) {
            case 'UP_LEFT': rotateAngle = -45; break;
            case 'UP_RIGHT': rotateAngle = 45; break;
            case 'BOTTOM_LEFT': rotateAngle = -135; break;
            case 'BOTTOM_RIGHT': rotateAngle = -135; break;
            case 'LEFT': rotateAngle = -90; break;
            case 'RIGHT': rotateAngle = 90; break;
            case 'BOTTOM': rotateAngle = 180; break;
        }
        const endingPointRotated = this.constructor.rotatePoint(current_mouse_position.x - elementCenter.x, elementCenter.y - current_mouse_position.y, rotateAngle);
        const scaleChangeRatio = (endingPointRotated.y / elementDiagonalLength);

        this.scale = Math.max(this.minScale, Math.min(this.maxScale, this.scale * scaleChangeRatio))
        /* Save mouse position */
        this._last_mouse_position = current_mouse_position;

        this.update();
    }

    update() {
        // Make sure the slide stays in its container area when zooming out
        if(this.boundingRect) {
            var upperLeftCornerX = this.boundingRect.origin.x * this.scale;
            var upperLeftCornerY = this.boundingRect.origin.y * this.scale;
            var bottomRightCornerX = (this.boundingRect.width - this.boundingRect.origin.x) * this.scale;
            var bottomRightCornerY = (this.boundingRect.height - this.boundingRect.origin.y)* this.scale;
            this.pos.x = Math.min(upperLeftCornerX, Math.max(this.viewportElem.width() - bottomRightCornerX, this.pos.x));
            this.pos.y = Math.min(upperLeftCornerY, Math.max(this.viewportElem.height() - bottomRightCornerY, this.pos.y));
        } else if(this.boundingElem) {
            this.pos.x = Math.min(this.boundingElem.width() - (this.contentElem.width() * 0.5 * this.scale), Math.max((this.contentElem.get(0).getBoundingClientRect().width) / 2 * this.scale, this.pos.x));
            this.pos.y = Math.min(this.boundingElem.height() - (this.contentElem.height() * 0.5 * this.scale), Math.max(this.contentElem.height() / 2 * this.scale, this.pos.y));
        }

        if(this.rotatedElement) this.rotatedElement.css('rotate', this.angle + 'deg');
        this.contentElem.css('scale', this.scale);
        this.contentElem.css('translate',(this.pos.x)+'px '+(this.pos.y)+'px');

        if(!this._manualUpdate)
            this.onUpdate(this.pos, this.scale, this.angle);
    }

    static rotatePoint(x, y, angle) {
        var angleRad = angle * Math.PI / 180;
        return {
            x: x * Math.cos(angleRad) - y * Math.sin(angleRad),
            y: x * Math.sin(angleRad) + y * Math.cos(angleRad)
        };
    }

    static computeAngleABC(vA, vC, vB = { x: 0, y: 0 }) {
        vA = { x: vA.x - vB.x, y: vA.y - vB.y };
        vC = { x: vC.x - vB.x, y: vC.y - vB.y };
        const dotProduct = vA.x * vC.x + vA.y * vC.y;
        const lengthAB = Math.sqrt(vA.x * vA.x + vA.y * vA.y);
        const lengthBC = Math.sqrt(vC.x * vC.x + vC.y * vC.y);
        const angleRadians = Math.acos(dotProduct / (lengthAB * lengthBC));
        var angle = (angleRadians * 180) / Math.PI;
        return vC.x >= 0 ? angle : -angle;
    }
}